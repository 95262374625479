import { FiHome as HomeIcon } from "react-icons/fi";
import {
  BreadcrumbComponentType,
  BreadcrumbMatch,
  BreadcrumbsRoute,
} from "use-react-router-breadcrumbs";

import BreadcrumbWithIDParam from "shared/components/BreadcrumbWithIDParam";

import AlertDefinitionBreadcrumbs from "pages/AlertDefinitions/AlertDefinitionBreadcrumbs";
import CalculatedAttributeBreadcrumb from "pages/CalculatedAttributes/CalculatedAttributeBreadcrumb";
import FailureModeBreadcrumb from "pages/FailureModes/FailureModeBreadcrumb";
import GroupBreadcrumb from "pages/Groups/GroupBreadcrumb";
import IssueBreadcrumb from "pages/Issues/IssueBreadcrumb";
import ServicePlanBreadcrumb from "pages/ServicePlans/ServicePlanBreadcrumb";
import FailureModeBreadcrumbV0 from "pages/V0_FailureMode/FailureModeBreadcrumb";

import { routes } from "./routes";

const NEW_RESOURCE_DEFAULT = "New";

interface UrlDecodedProps {
  match: BreadcrumbMatch;
}

const UrlDecodedFleet = ({ match }: UrlDecodedProps) => {
  return <>{decodeURIComponent(match.params.fleetName || "")}</>;
};

const UrlDecodedVIN = ({ match }: UrlDecodedProps) => {
  return <>{decodeURIComponent(match.params.vin || "")}</>;
};

const Home: BreadcrumbComponentType<any> = () => {
  return <HomeIcon />;
};

// An array of routes we want to override default naming for (or force-hide the breadcrumb for it)
export const routesBreadcrumbs: BreadcrumbsRoute[] = [
  { path: routes.root, breadcrumb: Home },
  { path: routes.fleetView, breadcrumb: UrlDecodedFleet },
  { path: routes.vinView, breadcrumb: UrlDecodedVIN },
  { path: routes.failureMode, breadcrumb: FailureModeBreadcrumbV0 },
  { path: routes.failureModesV1, breadcrumb: "Failure Modes" },
  { path: routes.failureModeV1, breadcrumb: FailureModeBreadcrumb },
  // For routes, we do not have a page underneath, we disable the breadcrumb and library will skip it
  { path: routes.dashboard, breadcrumb: null },
  { path: routes.servicePlan, breadcrumb: ServicePlanBreadcrumb },
  { path: routes.newServicePlan, breadcrumb: NEW_RESOURCE_DEFAULT },
  {
    path: routes.calculatedAttribute,
    breadcrumb: CalculatedAttributeBreadcrumb,
  },
  {
    path: routes.newCalculatedAttribute,
    breadcrumb: NEW_RESOURCE_DEFAULT,
  },
  { path: routes.issue, breadcrumb: IssueBreadcrumb },
  { path: routes.suggestedIssue, breadcrumb: "Suggested Issue" },
  { path: routes.signalEventStudio, breadcrumb: null },
  // only used, so we get rid of the ID part of the breadcrumb on SI details page
  { path: routes.suggestedIssueLatestRun, breadcrumb: null },
  {
    path: routes.claimAnalyticsDetails,
    breadcrumb: BreadcrumbWithIDParam,
    props: { entity: "Claim" },
  },
  {
    path: routes.inspectionAnalyticsDetails,
    breadcrumb: BreadcrumbWithIDParam,
    props: { entity: "Inspection" },
  },
  {
    path: routes.repairAnalyticsDetails,
    breadcrumb: BreadcrumbWithIDParam,
    props: { entity: "Repair" },
  },
  { path: routes.group, breadcrumb: GroupBreadcrumb },
  { path: routes.alertDefinition, breadcrumb: AlertDefinitionBreadcrumbs },
  { path: routes.newAlertDefinition, breadcrumb: NEW_RESOURCE_DEFAULT },
];
