import { PageState } from "duck/graph/types";
import type { Document } from "@langchain/core/documents";
import { BaseMessage } from "@langchain/core/messages";
import { Annotation, messagesStateReducer } from "@langchain/langgraph/web";

/**
 * @summary Define the type of the state graph for the agent.
 */
export type GraphStateType = typeof graphState.State;

/**
 * @summary Define the state graph for the agent.
 * This is expected to be the same for most if not all top-level agents.
 */
export const graphState = Annotation.Root({
  messages: Annotation<BaseMessage[]>({
    reducer: messagesStateReducer,
  }),
  pageState: Annotation<PageState>(),
  next: Annotation<string>(),
  documents: Annotation<Document[]>({
    reducer: (_, y) => y,
    default: () => [],
  }),
});
