import getDocumentRetrievalTool from "duck/graph/tools/getDocumentRetrievalTool";
import getTheRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { RequestParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

const DOCUMENTS_SOURCE = "Confluence";

let respondToUserTool: StructuredTool;
let retrieveDocumentsTool: StructuredTool;

/**
 * @summary Get the tools for the claim analytics agent.
 * The tools are specific to claim analytics, and are bound to the UI handlers
 * for claim analytics.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the claim analytics agent.
 */
const getTools = (params: RequestParams): StructuredTool[] => {
  respondToUserTool = getTheRespondToUserTool(
    params.uiHandlers.setAgentResponse
  );
  retrieveDocumentsTool = getDocumentRetrievalTool(DOCUMENTS_SOURCE);

  return [respondToUserTool, retrieveDocumentsTool];
};

export default getTools;
