import {
  createStrictToolCallingAgentNode,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { RequestParams } from "duck/graph/types";
import { getLLM } from "duck/graph/utils";
import { StructuredTool } from "@langchain/core/tools";
import { ToolNode } from "@langchain/langgraph/prebuilt";

import getPrompt from "./getPrompt";
import getTools from "./tools/getTools";

let tools: StructuredTool[];

const getNode = async (params: RequestParams): Promise<NodeType> => {
  const prompt = await getPrompt();
  const llm = getLLM();
  tools = getTools(params);
  return createStrictToolCallingAgentNode(llm, tools, prompt);
};

export const getToolNode = (): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>(tools);

export default getNode;
