import { formatDocs } from "duck/graph/nodes/utils";
import { z } from "zod";
import { Document } from "@langchain/core/documents";
import { tool } from "@langchain/core/tools";

import {
  vectorStoreSearch,
  VectorStoreSearchParameters,
} from "shared/api/vectorstore/api";

const retrieveRelevantDocuments = async (
  query: string,
  source?: string,
  k?: number,
  distanceThreshold?: number
): Promise<Document[]> => {
  console.debug("Retrieving relevant documents", {
    query,
    source,
    k,
    distanceThreshold,
  });

  const params: VectorStoreSearchParameters = {
    query,
    k,
    distanceThreshold,
    source,
  };
  const { data } = await vectorStoreSearch(params);

  if (!data) {
    console.error("No documents found for the given question.");
    return [];
  }

  const documents = data.map((result) => {
    const { documentID, document, title, url, metadata } = result;
    console.log("Document retrieved:", {
      documentID,
      document,
      title,
      url,
      source,
      metadata,
    });

    let parsedMetadata: Record<string, any>;
    try {
      parsedMetadata = JSON.parse(metadata);
    } catch (error) {
      console.error("Failed to parse metadata:", error);
      parsedMetadata = { rawMetadata: metadata };
    }

    return new Document({
      pageContent: document,
      metadata: { title, url, source, ...parsedMetadata },
      id: documentID,
    });
  });

  return documents;
};

const RetrieveDocumentsSchema = z.object({
  query: z.string().describe("Query to retrieve documents for."),
  // k: z.number().optional().describe("Number of attributes to retrieve."),
});

type RetrieveDocuments = z.infer<typeof RetrieveDocumentsSchema>;

const getDocumentRetrievalTool = (source: string) => {
  const retrieveDocuments = async ({
    query,
    // k,
  }: RetrieveDocuments) => {
    console.debug(`Retrieving ${source} attributes`, { query });

    const documents = await retrieveRelevantDocuments(query, source, 10, 2);

    if (documents.length === 0) {
      return "No documents found for the given query.";
    }

    return `Retrieved ${documents.length} relevant ${source} attributes sorted by relevance.\n\n${formatDocs(documents)}`;
  };

  return tool(retrieveDocuments, {
    name: "retrieveDocuments",
    description:
      "Call this tool to retrieve relevant documents for a given query.",
    schema: RetrieveDocumentsSchema,
  });
};

export default getDocumentRetrievalTool;
