import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";
import { listFailureModeAttributesRequestURI } from "./api";

export const useFailureModes = (args: api.ListFailureModesRequest) =>
  usePaginatedAPI<typeof args, api.FailureMode[]>(
    api.listFailureModesRequestURI,
    args
  );

export const useFailureMode = (args: api.GetFailureModeRequest) =>
  useAPI<typeof args, api.FailureMode>(api.getFailureModeRequestURI, args);

export const useFailureModesCount = (args: api.ListFailureModesRequest) =>
  useAPI<typeof args, CountResponse>(api.countFailureModesRequestURI, args);

export const useFailureModeAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listFailureModeAttributesRequestURI,
    args
  );
