import { navigateToTab } from "duck/graph/handlers/queryStringNavigation";
import {
  setByVehicleAgeChartOptions,
  setClaimsChartOptions,
  setSelectedGroupByAttributeFromId,
  updateClaimsFilter,
  updateSignalEventOccurrencesFilter,
  updateSignalEventOccurrencesWindowSize,
  updateVehiclesFilter,
} from "duck/graph/nodes/ClaimAnalyticsAgent/handlers";
import getAttributeRetrievalTool, {
  ATTRIBUTE_SOURCE_KEYS,
} from "duck/graph/tools/getAttributeRetrievalTool";
import getTheRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import getUpdateClaimsFilterTool from "duck/graph/tools/getUpdateClaimsFilterTool";
import getUpdateVehiclesFilterTool from "duck/graph/tools/getUpdateVehiclesFilterTool";
import { RequestParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import getGoToAssociatedSignalEventsTabTool from "./getGoToAssociatedSignalEventsTabTool";
import getGoToAssociatedVehiclesTabTool from "./getGoToAssociatedVehiclesTabTool";
import getGoToClaimsByVehiclesAgeTabTool from "./getGoToClaimsByVehiclesAgeTabTool";
import getGoToClaimsTabTool from "./getGoToClaimsTabTool";
import getGoToTopContributorsTabTool from "./getGoToTopContributorsTabTools";

let respondToUserTool: StructuredTool;

/**
 * @summary Get the tools for the claim analytics agent.
 * The tools are specific to claim analytics, and are bound to the UI handlers
 * for claim analytics.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the claim analytics agent.
 */
const getTools = (params: RequestParams): StructuredTool[] => {
  respondToUserTool = getTheRespondToUserTool(
    params.uiHandlers.setAgentResponse
  );
  const ATTRIBUTE_RETRIEVAL_K = 5;
  const ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD = 2;

  return [
    getAttributeRetrievalTool(
      ATTRIBUTE_SOURCE_KEYS.Claim,
      ATTRIBUTE_RETRIEVAL_K,
      ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD
    ),
    getAttributeRetrievalTool(
      ATTRIBUTE_SOURCE_KEYS.Vehicle,
      ATTRIBUTE_RETRIEVAL_K,
      ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD
    ),
    getUpdateClaimsFilterTool(updateClaimsFilter),
    getUpdateVehiclesFilterTool(updateVehiclesFilter),
    getGoToClaimsTabTool(
      navigateToTab,
      setClaimsChartOptions,
      params.claimsChartOptions
    ),
    getGoToClaimsByVehiclesAgeTabTool(
      navigateToTab,
      setByVehicleAgeChartOptions,
      params.byVehicleAgeChartOptions
    ),
    getGoToTopContributorsTabTool(
      navigateToTab,
      setSelectedGroupByAttributeFromId
    ),
    getGoToAssociatedVehiclesTabTool(navigateToTab),
    getGoToAssociatedSignalEventsTabTool(
      navigateToTab,
      updateSignalEventOccurrencesFilter,
      updateSignalEventOccurrencesWindowSize
    ),
    respondToUserTool,
  ];
};

export const getRespondToUserTool = (): StructuredTool => respondToUserTool;

export default getTools;
