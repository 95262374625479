import {
  logUnsupportedAction,
  Reload,
  updateFilter,
  updateQueryStringParameter,
} from "duck/graph/handlers/queryStringNavigation";

import {
  CLAIMS_PAGE_KEY,
  CLAIMS_TAB_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { CHART_OPTIONS_KEY as CLAIMS_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/Claims/ClaimsChart";

import {
  getFiltersKey,
  getPageKeyWithVersion,
  getQueryKeys,
  getStateFromLocalStorage,
  injectPageChartSettings,
} from "features/ui/Filters/utils";

export const setByVehicleAgeChartOptions = (...args: any[]) => {
  // (yAxis?: string, xAxis?: string, granularity?: string, exposure?: string)
  logUnsupportedAction("setByVehicleAgeChartOptions", args);

  // setByVehicleAgeSelectedOptions([
  //   { id: "y", optionId: yAxis || byVehicleAgeSelectedOptions[0].optionId },
  //   { id: "x", optionId: xAxis || byVehicleAgeSelectedOptions[1].optionId },
  //   {
  //     id: "granularity",
  //     optionId: granularity || byVehicleAgeSelectedOptions[2].optionId,
  //   },
  //   {
  //     id: "exposure",
  //     optionId: exposure || byVehicleAgeSelectedOptions[3].optionId,
  //   },
  // ]);
};

export const setClaimsChartOptions = (yAxis?: string) => {
  if (!yAxis) {
    return;
  }

  const pageKeyWithVersion = getPageKeyWithVersion(CLAIMS_PAGE_KEY);
  const { chartSettings } = getStateFromLocalStorage(pageKeyWithVersion);
  const nextChartSettings = {
    [CLAIMS_TAB_KEY]: {
      [CLAIMS_CHART_OPTIONS_KEY]: [{ id: "y", optionId: String(yAxis) }],
    },
  };
  const updatedChartSettings = injectPageChartSettings(
    chartSettings ?? {},
    nextChartSettings
  );

  const { chartSettingsKey } = getQueryKeys(pageKeyWithVersion);

  updateQueryStringParameter(
    chartSettingsKey,
    JSON.stringify(updatedChartSettings),
    Reload.HARD
  );
};

export const setSelectedGroupByAttributeFromId = (
  groupByAttributeId: string
) => {
  logUnsupportedAction("setSelectedGroupByAttributeFromId", [
    groupByAttributeId,
  ]);
  // if (groupByAttributeId) {
  //   console.debug("Updating group by attribute");
  //   const selectedGroupByAttribute = groupBySelectOptions.find(
  //     (option) => option.id === groupByAttributeId
  //   );
  //   if (selectedGroupByAttribute) {
  //     console.debug(
  //       "Setting selected group by attribute:",
  //       selectedGroupByAttribute
  //     );
  //     setSelectedGroupByAttribute(selectedGroupByAttribute);
  //     return "success";
  //   } else {
  //     const availableIds = groupBySelectOptions
  //       .map((option) => option.id)
  //       .join(", ");
  //     throw new Error(
  //       `Invalid group by attribute, please try to use one of the valid options ONLY. If it's a vehicle attribute, make sure to prepend the vehicle attributes with 'vehicle.' prefix. Available options are: ${availableIds}`
  //     );
  //   }
  // }
};

export const updateClaimsFilter = (filterQueryString: string): void =>
  updateFilter(filterQueryString, "claims", getFiltersKey(CLAIMS_PAGE_KEY));

export const updateSignalEventOccurrencesFilter = (
  filterQueryString: string
) => {
  logUnsupportedAction("updateSignalEventOccurrencesFilter", [
    filterQueryString,
  ]);
  // if (filterQueryString) {
  //   const filters = filterBuilderQueryToFilterBuilderState(filterQueryString);
  //   if (filters) {
  //     (relatesFilter.filters.children[0] as FilterRowState).attribute = (
  //       filters.children[0] as FilterRowState
  //     ).attribute;
  //     (relatesFilter.filters.children[0] as FilterRowState).operator = (
  //       filters.children[0] as FilterRowState
  //     ).operator;
  //     (relatesFilter.filters.children[0] as FilterRowState).values = (
  //       filters.children[0] as FilterRowState
  //     ).values;
  //   } else {
  //     throw new Error(
  //       "Invalid filter string for signal event occurrences, please try again."
  //     );
  //   }
  // }
  // setRelatesFilter(relatesFilter);
  // setAppliedRelatesFilter(relatesFilter);
};

export const updateSignalEventOccurrencesWindowSize = (windowSize: number) => {
  logUnsupportedAction("updateSignalEventOccurrencesWindowSize", [windowSize]);
  // if (windowSize) {
  //   if (windowSize < MIN_WINDOW_SIZE || windowSize > MAX_WINDOW_SIZE) {
  //     throw new Error(
  //       `Invalid window size, please enter a value between ${MIN_WINDOW_SIZE} and ${MAX_WINDOW_SIZE}.`
  //     );
  //   }

  //   // Update window size
  //   relatesFilter.options.windowSize = windowSize.toString();
  // }
};

export const updateVehiclesFilter = (filterQueryString: string) =>
  updateFilter(filterQueryString, "vehicles", getFiltersKey(VEHICLES_PAGE_KEY));
