import { GraphStateType } from "duck/graph/state";
import { END } from "@langchain/langgraph/web";
import { ChatOpenAI } from "@langchain/openai";

import { MODELSPEC, OPENAI_API_KEY } from "./constants";

/**
 * Respond if the last message is END tool call, END
 * otherwise, continue to the tools node
 * @summary Conditional routing function for the agent
 * @param state
 * @returns An indicator of what the agent should do next
 */
export const shouldContinue = (state: GraphStateType): "tools" | typeof END => {
  const { messages } = state;
  const lastMessage = messages[messages.length - 1];
  if (
    "tool_calls" in lastMessage &&
    Array.isArray(lastMessage.tool_calls) &&
    lastMessage.tool_calls?.length
  ) {
    if (lastMessage.tool_calls[0].name === "END") {
      return END;
    }
    return "tools";
  }

  return "tools";
};

export const getLLM = () => {
  return new ChatOpenAI({
    openAIApiKey: OPENAI_API_KEY,
    model: MODELSPEC.modelName,
    temperature: MODELSPEC.temperature,
    modelKwargs: MODELSPEC.modelKwargs,
  });
};
