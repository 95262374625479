import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { DuckMessagesContext } from "duck/context/DuckMessagesContextWrapper";
import callGraph from "duck/graph";
import {
  deliverLocationInfo,
  Reload,
} from "duck/graph/handlers/queryStringNavigation";
import { UIHandlers } from "duck/graph/types";
import { TextField } from "@mui/material";

import { useAgentData } from "./hooks";

type DuckTextInputProps = {
  threadId: string;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setPendingAction: Dispatch<SetStateAction<boolean>>;
};

const DuckTextInput = ({
  threadId,
  loading,
  setLoading,
  setPendingAction,
}: DuckTextInputProps) => {
  const [utterance, setUtterance] = useState("");
  const textFieldRef = useRef<HTMLTextAreaElement>(null);

  const { messages, addMessage } = useContext(DuckMessagesContext);

  const {
    claimsChartOptions,
    byVehicleAgeChartOptions,
    groupBySelectOptions,
    getPageState,
  } = useAgentData();

  const handleAgentResponse = (message: string) => {
    addMessage({
      author: "agent",
      message,
    });
  };

  const uiHandlers: UIHandlers = {
    setAgentResponse: handleAgentResponse,
  };

  const submit = async (): Promise<void> => {
    const currentUtterance = utterance.trim();

    const priorMessages = [...messages];

    addMessage({
      author: "human",
      message: currentUtterance,
    });

    setUtterance("");
    setLoading(true);

    // Make sure that the page state is up to date
    const currentState = getPageState();

    try {
      await callGraph({
        text: currentUtterance,
        messageHistory: priorMessages,
        currentState,
        uiHandlers,
        threadId,
        groupBySelectOptions,
        byVehicleAgeChartOptions,
        claimsChartOptions,
      });

      const { reloadRequired } = deliverLocationInfo(false);
      if (reloadRequired === Reload.HARD || reloadRequired === Reload.SOFT) {
        setPendingAction(true);
      }
    } catch (error) {
      console.error(`${new Date().getTime()} error`, error);
      addMessage({
        author: "agent",
        message: String((error as { message?: string }).message),
      });
    } finally {
      setLoading(false);
    }
  };

  const assignFocus = async () => {
    // This brief delay is necessary for this to operate correctly
    await new Promise((resolve) => setTimeout(resolve, 10));
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey && !!utterance.trim()) {
      event.preventDefault(); // Prevent default to avoid new line in multiline input
      await submit();
      await assignFocus();
    }
  };

  return (
    <TextField
      inputRef={textFieldRef}
      multiline={true}
      minRows={1}
      maxRows={3}
      value={utterance}
      placeholder="Ask something"
      onChange={(e: ChangeEvent<HTMLTextAreaElement>): void =>
        setUtterance(e.target.value)
      }
      onKeyDown={handleKeyDown}
      slotProps={{
        inputLabel: {
          style: { color: "text.secondary" },
        },
        input: {
          style: {
            padding: "6px 8px",
          },
        },
      }}
      variant="outlined"
      margin="none"
      size="small"
      disabled={loading}
      autoFocus={true}
      fullWidth={true}
      style={{
        backgroundColor: "white",
        borderRadius: "0.375rem",
        minHeight: "34px",
      }}
    />
  );
};

export default DuckTextInput;
