import { Handlers } from "duck/graph/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

const updateVehiclesFilterSchema = z.object({
  vehiclesFilter: z.string().optional().describe("Filter string for vehicles."),
});

type UpdateVehiclesFilter = z.infer<typeof updateVehiclesFilterSchema>;

const getUpdateVehiclesFilterTool = (
  updateVehiclesFilterHandler: Handlers["updateVehiclesFilter"]
): DynamicStructuredTool => {
  const updateVehiclesFilter = ({
    vehiclesFilter,
  }: UpdateVehiclesFilter): string => {
    console.debug("Updating vehicles filters", { vehiclesFilter });
    if (vehiclesFilter) {
      updateVehiclesFilterHandler(vehiclesFilter);
    }
    return "success";
  };

  return tool(updateVehiclesFilter, {
    name: "queueUpdateVehiclesFilter",
    description:
      "Call this tool to queue an action that updates the vehicles filters given a text query. Only call this for vehicle attributes.",
    schema: updateVehiclesFilterSchema,
  });
};

export default getUpdateVehiclesFilterTool;
