import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";

const FAILRUE_MODES_BASE_ROUTE = "failureModes";

export type FailureModeStatus = "active" | "archived" | "under_review";
export type FailureModeType = "consumable" | "durable";

export interface FailureMode {
  ID: string;
  name: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  type: "consumable" | "durable";
  vehiclesFilter: string;
  failuresRepairsFilter: string | null;
  repairsCustomRecordsFilter: string | null;
  deduplicateWindow: number | null;
  failuresSignalEventOccurrencesFilter: string | null;
  repairsSignalEventOccurrencesFilter: string | null;
  repairsInspectionsFilter: string | null;
  repeatFailureAllowed: boolean;
  recallRepairCost: number | null;
  reactiveRepairCost: number | null;
  currencyCode: "USD" | "EUR" | null;
  failuresClaimFilter: string | null;
  repairsRepairsFilter: string | null;
  status: FailureModeStatus;
  failuresInspectionsFilter: string | null;
  failuresCustomRecordsFilter: string | null;
  repairsClaimsFilter: string | null;
  deduplicateEvents: boolean;
  proactiveRepairCost: number | null;
}

export interface ListFailureModesRequest extends APIPaginatedRequest {}

export interface GetFailureModeRequest {
  id: string;
}

export const listFailureModesRequestURI = (
  params: ListFailureModesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILRUE_MODES_BASE_ROUTE]),
    params,
  });

export const getFailureModeRequestURI = ({
  id,
  ...params
}: GetFailureModeRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILRUE_MODES_BASE_ROUTE, id]),
    params,
  });

export const countFailureModesRequestURI = (
  params: ListFailureModesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILRUE_MODES_BASE_ROUTE, "count"]),
    params,
  });

// attributes
export const listFailureModeAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILRUE_MODES_BASE_ROUTE, "attributes"]),
    params,
  });

// values
export const listFailureModesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listFailureModesValuesRequestURI(args));

const listFailureModesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILRUE_MODES_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const getFailureModesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getFailureModesExportRequestURI(args), {
    responseType: "blob",
  });

const getFailureModesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILRUE_MODES_BASE_ROUTE, "export", type]),
    params,
  });
