import { LC_API_KEY, LC_ENDPOINT } from "duck/graph/constants";
import * as hub from "langchain/hub";
import { ChatPromptTemplate } from "@langchain/core/prompts";

const PROMPT_COMMIT_ID = "claim_analytics_expert_v2";

// Cache the prompts so that we only retrieve them once.
// The cache will be cleared when the page is reloaded.
let claimAnalyticsPrompt: ChatPromptTemplate;

const getPrompt = async (): Promise<ChatPromptTemplate> => {
  if (claimAnalyticsPrompt) {
    return claimAnalyticsPrompt;
  }

  claimAnalyticsPrompt = await hub.pull<ChatPromptTemplate>(PROMPT_COMMIT_ID, {
    apiKey: LC_API_KEY,
    apiUrl: LC_ENDPOINT,
    includeModel: false,
  });

  return claimAnalyticsPrompt;
};

export default getPrompt;
