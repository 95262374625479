import { LC_API_KEY, LC_ENDPOINT } from "duck/graph/constants";
import * as hub from "langchain/hub";
import { ChatPromptTemplate } from "@langchain/core/prompts";

import { NEXT_NODE_OPTIONS } from "./getNode";

const PROMPT_COMMIT_ID = "duck_supervisor";

// Cache the prompts so that we only retrieve them once.
// The cache will be cleared when the page is reloaded.
let supervisorPrompt: ChatPromptTemplate;

const getPrompt = async (
  options: string[] = NEXT_NODE_OPTIONS
): Promise<ChatPromptTemplate> => {
  if (supervisorPrompt) {
    return supervisorPrompt;
  }
  const initialPrompt = await hub.pull<ChatPromptTemplate>(PROMPT_COMMIT_ID, {
    apiKey: LC_API_KEY,
    apiUrl: LC_ENDPOINT,
  });

  supervisorPrompt = await initialPrompt.partial({
    options: options.join(","),
  });

  return supervisorPrompt;
};

export default getPrompt;
